import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DataTable } from '..';

/**
 * @typedef {Object} limit
 * @property {string} id
 * @property {number} value
 */

/**
 * @typedef {object} credit_limit
 * @property {string} desc
 * @property {string|number} id
 * @property {number[]} ixAcc
 * @property {number[]} ixJCd
 * @property {number} ixSubType
 * @property {0|1} mode
 * @property {string} sSubType
 */

/**
 * @param {Array<credit_limit>} credit_limits
 * @param {Array<{id:number|string, value:number}>} value
 * @returns {Array<{id: string, desc: string, value: number}>}
 */
function generateTableData(credit_limits = [], value = []) {
  const res = [];

  for (const credit_limit of credit_limits) {
    res.push({
      id: credit_limit.id,
      desc: credit_limit.desc,
      value: value.find(item => item.id === credit_limit.id)?.value || 0
    });
  }

  return res;
}

const CreditLimit = ({ data, onChangeField }) => {
  const { frontEnd = {} } = useSelector(state => state.bizMeta);

  /**
   * @type {{allow:boolean, credit_limit: Array<credit_limit}>}
   */
  const { allow: allowCreditLimit = false, items: creditLimitItems = [] } =
    frontEnd?.credit_limit || {};

  if (!allowCreditLimit) return null;

  const tableData = generateTableData(creditLimitItems, data?.value || []);

  /**
   * @param {cellParams} params
   */
  function editCell(params) {
    onChangeField({
      target: {
        name: data?.fld || '',
        value: tableData.map(item => {
          if (item.id === params.id)
            item.value = parseFloat(params.value || '0');

          return {
            id: item.id,
            value: item.value
          };
        })
      }
    });
  }

  return (
    <Box>
      <DataTable
        data={tableData}
        columns={[
          { field: 'desc', headerName: 'Description', flex: 1, minWidth: 300 },
          {
            field: 'value',
            headerName: 'Value',
            flex: 1,
            minWidth: 150,
            align: 'right',
            headerAlign: 'center',
            editable: true
          }
        ]}
        hideToolbar
        paginate={false}
        onCellEdit={editCell}
      />
    </Box>
  );
};

export default CreditLimit;
