function restructureData(data = []) {
  const res = [];

  for (const row of data) {
    const provinceIndex = res.findIndex(
      item => item.province === row.stateProv
    );

    if (provinceIndex === -1) {
      res.push({
        province: row.stateProv,
        cities: [
          {
            city: row.cityMuni,
            barangays: [{ barangay: row.townBar }]
          }
        ]
      });
    } else {
      const cityIndex = res[provinceIndex].cities.findIndex(
        city => city.city === row.cityMuni
      );

      if (cityIndex === -1) {
        res[provinceIndex].cities.push({
          city: row.cityMuni,
          barangays: [{ barangay: row.townBar }]
        });
      } else {
        res[provinceIndex].cities[cityIndex].barangays.push({
          barangay: row.townBar
        });
      }
    }
  }

  return res;
}

export default restructureData;

function getAddressOptions(
  restructuredAddress = [],
  addProv = '',
  addMuniCity = ''
) {
  if (!Array.isArray(restructuredAddress))
    return { provinces: [], cities: [], barangays: [] };

  const provinces = restructuredAddress.map(prov => prov.province);
  const provIndex = provinces.indexOf(String(addProv));

  const cities = provIndex !== -1 ? restructuredAddress[provIndex].cities : [];
  const townCities = cities.map(city => city.city);

  const cityIndex = townCities.indexOf(String(addMuniCity));
  const cityBarangays = cityIndex !== -1 ? cities[cityIndex].barangays : [];

  const barangays = cityBarangays.map(barangay => barangay.barangay);

  return { provinces, townCities, barangays };
}

export { getAddressOptions };

const validateAddress = (
  subAcc,
  selectedProvince,
  selectedTownCity,
  selectedBarangay
) => {
  const isAddressIncomplete =
    !subAcc?.addressProvince ||
    !subAcc?.addressCity ||
    !subAcc?.addressBrgy ||
    !selectedProvince ||
    !selectedTownCity ||
    !selectedBarangay;

  const isProvinceIncomplete = !selectedProvince;
  const isCityIncomplete = !selectedTownCity;
  const isBrgyIncomplete = !selectedBarangay;

  const isProvValue = subAcc?.addressProvince;
  const isCityValue = subAcc?.addressCity;
  const isBrgyValue = subAcc?.addressBrgy;

  return {
    isAddressIncomplete,
    isProvinceIncomplete,
    isCityIncomplete,
    isBrgyIncomplete,
    isProvValue,
    isCityValue,
    isBrgyValue
  };
};

export { validateAddress };

const spreadCommas = text => {
  let words = text.split(' ');
  let results = [];

  const generateCombinations = (current, index) => {
    if (index >= words.length) {
      results.push(current.join(' '));
      return;
    }

    generateCombinations([...current, words[index]], index + 1);

    if (current.length > 0) {
      generateCombinations([...current, ',', words[index]], index + 1);
    }
  };

  generateCombinations([words[0]], 1);
  return results;
};

export { spreadCommas };
