import React, { useEffect, useState } from 'react';
import useAddress from './useAddress';
import addressData from 'src/assets/dataJson/address_data.json';
import restructureData from './StracturedAddressData';
import AddressModal from './components/AddressModal';

const AddressResult = ({ onEntert, onsubmitData, openDlg, closeDlg }) => {
  const formattedData = restructureData(addressData);
  const [openDialog, setOpenDialog] = useState(false);

  const { prov, city, barangay, zip, street } = useAddress({
    formattedData,
    onEntert,
    handleSetDelimiter: ','
  });
  const submitValues = () => {
    onsubmitData({
      addProvince: prov,
      addMuniCities: city,
      addBarangays: barangay,
      addZIP: zip,
      addStreet: street
    });
  };

  const emptyValues = () => {
    onsubmitData({
      addProvince: '',
      addMuniCities: '',
      addBarangays: '',
      addZIP: '',
      addStreet: ''
    });
  };

  useEffect(() => {
    if (onEntert) {
      if (prov && (!city || !barangay)) {
        setOpenDialog(true);
      } else if (prov || city || barangay || zip || street) {
        submitValues();
      }
      if (!prov && !city && !barangay) {
        emptyValues();
        setOpenDialog(true);
      }
    } else {
      emptyValues();
    }
  }, [onEntert, prov, city, barangay, zip, street]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    closeDlg(false);
  };

  const handleOnsubmitData = data => {
    onsubmitData(data);
  };

  return (
    <>
      {(openDialog || openDlg) && (
        <AddressModal
          formattedData={formattedData}
          openDialog={openDialog}
          openDlg={openDlg}
          closeDialog={handleCloseDialog}
          onEntert={onEntert}
          onsubmitData={handleOnsubmitData}
        />
      )}
    </>
  );
};

export default AddressResult;
