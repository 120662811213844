import { useSnackbar } from 'notistack';

/**
 *
 * @returns {notify}
 */
const useNotif = () => {
  const { enqueueSnackbar } = useSnackbar();

  const success = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'success', ...options });
  };

  const info = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'info', ...options });
  };

  const warning = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'warning', ...options });
  };

  const error = (text, options = {}) => {
    enqueueSnackbar(text, { variant: 'error', ...options });
  };

  return { success, info, warning, error };
};

export default useNotif;
