import { useSelector } from 'react-redux';
import useJNTApi from './useJNTApi';
import useRequest from '../useRequest';
import _, { isEmpty } from 'lodash';
import { wait } from 'src/utils';

function useCourierService() {
  const { frontEnd } = useSelector(({ bizMeta }) => bizMeta);
  const jnt = useJNTApi();
  const req = useRequest();

  /**
   * Creates a new order using the specified courier service.
   * @param {string} [type=''] - The type of courier service to use for the order.
   * @param {Object} data - The order details to be sent to the courier service.
   * @returns {Promise<Object>} - The response object indicating the success status and any explanations for errors.
   */
  async function createOrder(type = '', data) {
    const map = {
      jnt: jnt.createOrder
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data);
  }

  /**
   * Tracks the given order with the specified courier service.
   * @param {string} [type=''] - The type of courier service to use for tracking the order.
   * @param {Object} [data=''] - The order details required for the tracking process.
   * @returns {Promise<Object>} - The response object indicating the success status and any explanations for errors.
   */
  async function trackOrder(type = '', data = '') {
    const map = {
      jnt: jnt.trackOrder
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data);
  }

  /**
   * Retrieves the order details for the given order with the given courier service.
   * @param {string} [type=''] - The type of courier service to use.
   * @param {Object} [data={}] - The order details to be generate.
   * @param {Object} [config={}] - The configuration object for the courier service.
   * @returns {Promise<Object>} - The response object from the API.
   */
  async function getOrderDetails(type = '', data = {}, config = {}) {
    const map = {
      jnt: jnt.getOrderDetails
    };

    if (!Boolean(map?.[type])) {
      return {
        success: false,
        explanation: 'Courier not yet supported'
      };
    }

    return await map[type](data, { ...config, ...(config?.[type] ?? {}) });
  }

  /**
   * Retrieves the courier setup, which contains the transaction details and their associated courier services,
   * a mapping of courier services to their corresponding codes, and the available courier service providers.
   * @returns {Object} - The courier setup object.
   * @property {Object} trans - The transaction details.
   * @property {Object} sub_map - The mapping of courier services to their corresponding codes.
   * @property {Object} providers - The available courier service providers.
   */
  /******  5745551f-8350-4877-b03a-46b9810ad390  *******/
  function getCourierSetup() {
    return (
      frontEnd?.api?.courier ?? {
        trans: {},
        sub_map: {},
        providers: {}
      }
    );
  }

  /**
   * Validates the setup of the courier service for a given transaction.
   * @param {Object} [jv={}] - The transaction object containing the necessary details for validation.
   * @returns {Object} - An object indicating the success status of the validation and an explanatory message.
   * If the setup is invalid, the message will indicate the specific issue:
   * - 'Courier service is not yet configured for this transaction' if the transaction does not have a configured courier service.
   * - 'Courier service field is unknown for this transaction.' if the courier service field is missing.
   * - 'Courier is required.' if the courier field is not provided in the transaction.
   * - 'Mapping of courier service is not properly configured.' if the courier mapping is incorrect.
   * - 'Waybill no already created.' if a waybill number has already been created.
   * If the setup is valid, the message will be 'Success'.
   */
  function validateSetup(jv = {}) {
    const ixJCd = jv?.ixJCd ?? 0;

    const { trans = {}, sub_map = {} } = getCourierSetup();

    if (!Boolean(trans?.[ixJCd])) {
      return {
        success: false,
        msg: 'Courier service is not yet configured for this transaction'
      };
    }

    if (!Boolean(trans?.[ixJCd]?.courier)) {
      return {
        success: false,
        msg: 'Courier service field is unknown for this transaction.'
      };
    }

    const courier_fld = jv?.[trans?.[ixJCd]?.courier] ?? 0;
    if (!Boolean(courier_fld)) {
      return {
        success: false,
        msg: 'Courier is required.'
      };
    }

    const courier = sub_map?.[courier_fld];
    if (!Boolean(courier)) {
      return {
        success: false,
        msg: 'Mapping of courier service is not properly configured.'
      };
    }

    const courier_settings = trans?.[ixJCd]?.[courier] ?? { mailno: '' };
    const kvs = jv?.kvs ?? [];

    if (kvs.find(k => k.key === courier_settings?.mailno && k.value !== '')) {
      return {
        success: false,
        msg: 'Waybill no already created.'
      };
    }

    return {
      success: true,
      msg: 'Success'
    };
  }

  /**
   * Retrieves the transaction details for a given array of transaction ids.
   * @param {Array<number>} [ids=[]] - The array of transaction ids.
   * @returns {Promise<Object[]>} - An array of transaction objects.
   * @resolve {Object} - The transaction object containing the necessary details.
   * @reject {Object} - The error object explaining the reason for the failure.
   */
  async function getItemsDetails(ids = []) {
    const result = await req.multiFetch(
      ids.map(id => ({
        url: `/trans/jv/${id}`
      }))
    );

    return result.map((item, i) => ({
      ...item,
      trans_id: ids[i]
    }));
  }

  /**
   * Retrieves detailed transaction information for a given transaction object.
   * @param {Object} [jv={}] - The transaction object containing the necessary details.
   * @returns {Object} - An object containing detailed transaction information.
   * @property {Object} jv - The original transaction object.
   * @property {number} ixJCd - The transaction index code.
   * @property {string} courier - The courier service associated with the transaction.
   * @property {number} courierFld - The field representing the courier service.
   * @property {Object} mailNoFld - The field for the waybill number.
   * @property {string} mailNoStatusFld - The field for the current waybill status.
   * @property {Object} wfMap - The workflow mapping for the courier.
   * @property {Object} transConfig - The transaction configuration including customer and weight.
   * @property {number} currentWFStatus - The current workflow status.
   * @property {string} waybillCurrentStatus - The current status of the waybill.
   * @property {string} waybill - The waybill number associated with the transaction.
   */
  function getTransactionDetails(jv = {}) {
    const ixJCd = jv?.ixJCd ?? 0;
    const kvs = jv?.kvs ?? [];
    const { trans = {}, sub_map = {} } = getCourierSetup();

    const courier_fld = jv?.[trans?.[ixJCd]?.courier] ?? 0;
    const courier = sub_map?.[courier_fld];
    const courier_settings = trans?.[ixJCd]?.[courier] ?? { mailno: '' };

    // get wf mapping
    const wf_map = trans?.[ixJCd]?.[courier]?.wf_map ?? {};

    // waybill kvs fld
    const mailNoFld = trans?.[ixJCd]?.[courier]?.mailno ?? {};

    // get current waybill status
    const mailno_status_fld = trans?.[ixJCd]?.[courier]?.mailno_status ?? '';
    const waybillCurrentStatus =
      jv?.kvs?.find(({ key }) => key === mailno_status_fld)?.value ?? '';

    const transConfig = trans?.[ixJCd] ?? {
      customer: '',
      weight: ''
    };

    return {
      jv,
      ixJCd,
      courier,
      courierFld: courier_fld,
      mailNoFld,
      mailNoStatusFld: mailno_status_fld,
      wfMap: wf_map,
      transConfig,
      currentWFStatus: jv?.wfStatus ?? 0,
      waybillCurrentStatus,
      waybill: kvs.find(k => k.key === courier_settings?.mailno)?.value ?? ''
    };
  }

  /**
   * Asynchronously updates the status of the given list of transaction IDs.
   * @param {number[]} ids - The list of transaction IDs to update.
   * @param {number} [itemsPerBatch=20] - The number of items to process per batch.
   * @param {function} [progressCallBack=params => {}] - The callback function to report progress.
   * @returns {Promise<Object>} - An object containing the results of the update operation.
   * The object contains the following properties:
   * - `errors`: An array of objects each containing the `id` and `msg` of the error.
   * - `success`: An array of objects each containing the `id` and `msg` of the success.
   * - `warnings`: An array of objects each containing the `id` and `msg` of the warning.
   */
  async function batchStatusUpdate(
    ids = [],
    itemsPerBatch = 20,
    progressCallBack = params => {},
    sleepMS = 3000
  ) {
    // create batch
    const batches = _.chunk(ids, itemsPerBatch);

    const for_update = [];
    const map = {
      jnt: jnt.trackOrders
    };
    const errors = [],
      success = [],
      warnings = [];

    let currentBatch = 1,
      itemsDone = 0;

    const setup = getCourierSetup();

    if (
      isEmpty(setup.trans) ||
      isEmpty(setup.sub_map) ||
      isEmpty(setup.providers)
    ) {
      return {
        warnings,
        success,
        errors: ids.map(item => ({
          id: item,
          msg: 'Courier settings are not configured correctly'
        }))
      };
    }

    for await (const batch of batches) {
      // init progress
      if (currentBatch === 1) {
        progressCallBack({
          batches: batches.length,
          currentBatch,
          progress: 0,
          batchItems: batch.length,
          totalItemsDone: 0,
          totalItems: ids.length,
          errors: 0,
          success: 0,
          warnings: 0
        });
      }

      const items = await getItemsDetails(batch);
      const courier_waybills = [];

      for (let i = 0; i < items.length; i++) {
        const { success, data } = items[i];

        if (!success) {
          errors.push({
            id: batch[i],
            msg: 'Failed to get transaction details.'
          });
          continue;
        }

        const { waybill, courier } = getTransactionDetails(data?.jv ?? {});
        if (waybill === '') {
          errors.push({
            id: batch[i],
            msg: 'Waybill is required.'
          });
          continue;
        }

        if (!Boolean(map?.[courier])) {
          errors.push({
            id: batch[i],
            msg: 'Courier not yet supported'
          });
          continue;
        }

        const courier_index = courier_waybills.findIndex(
          item => item.courier === courier
        );

        if (courier_index < 0) {
          courier_waybills.push({
            courier,
            items: [
              {
                id: batch[i],
                waybill
              }
            ]
          });
        } else {
          courier_waybills[courier_index].items.push({
            id: batch[i],
            waybill
          });
        }
      }

      // get order status by courier
      for await (const orders of courier_waybills) {
        const result = await map[orders.courier](
          orders.items.map(item => item.waybill)
        );

        result.forEach(({ success, billcode, explanation, details }) => {
          if (!success) {
            errors.push({
              id: orders.items.find(item => item.waybill === billcode).id,
              msg: explanation
            });
            return;
          }

          for_update.push({
            id: orders.items.find(item => item.waybill === billcode).id,
            waybill: billcode,
            details
          });
        });
      }

      // update workflow status
      for await (const { id, details } of for_update) {
        const jvDetails =
          items.find(({ trans_id }) => trans_id === id)?.data?.jv ?? {};

        const {
          jv,
          wfMap,
          waybillCurrentStatus,
          mailNoStatusFld,
          currentWFStatus
        } = getTransactionDetails(jvDetails);

        if (isEmpty(wfMap)) {
          errors.push({
            id,
            msg: 'DaloyWF Status mapping not properly configured.'
          });
          continue;
        }

        if (details.length === 0) {
          success.push({
            id,
            msg: 'No changes found.'
          });
          continue;
        }

        const updatedWaybillStatus = details[0].code;

        if (+updatedWaybillStatus === +waybillCurrentStatus) {
          success.push({
            id,
            msg: 'No changes found.'
          });
          continue;
        }

        // update status code
        const updateWaybillStatusRes = await req.put(
          `/trans/jcd/${jv?.jcd_uuid}/save`,
          {
            jid: jv?.jid,
            ixTL: jv?.ixTL,
            kvs: {
              [mailNoStatusFld]: updatedWaybillStatus
            }
          }
        );

        if (!updateWaybillStatusRes.success) {
          errors.push({
            id,
            msg: 'Failed to update waybill status code'
          });
        }

        const updatedWFStatus = wfMap?.[updatedWaybillStatus] || 0;

        // update of wf status
        if (currentWFStatus === updatedWFStatus) continue;

        const updateWFStatusRes = await req.post(
          `/trans/jv/${jv?.jid}/wf/update`,
          {
            wfStatus: updatedWFStatus,
            remarks: ''
          }
        );

        if (!Boolean(updateWFStatusRes?.success)) {
          errors.push({
            id,
            msg:
              updateWFStatusRes?.errorMessage ||
              'Failed to update DaloyWF status'
          });
          continue;
        }

        success.push({
          id,
          msg: updateWFStatusRes?.data?.message || 'DaloyWF status updated.'
        });

        // warnings
        (updateWFStatusRes?.data?.errors?.warnings ?? []).forEach(warning => {
          if (warning?.msg) {
            warnings.push({
              id,
              msg: warning.msg
            });
          }
        });

        // errors
        (updateWFStatusRes?.data?.errors?.errors ?? []).forEach(error => {
          if (error?.msg) {
            errors.push({
              id,
              msg: error.msg
            });
          }
        });
      }

      // update progress
      if (batches.length !== currentBatch) currentBatch += 1;
      itemsDone += batch.length;

      progressCallBack({
        batches: batches.length,
        currentBatch,
        progress: itemsDone / ids.length,
        batchItems: batches[currentBatch - 1].length,
        totalItemsDone: itemsDone,
        totalItems: ids.length,
        errors: errors.length,
        success: success.length,
        warnings: warnings.length
      });

      // sleep
      if (batches.length >= currentBatch) await wait(sleepMS);
    }

    return {
      errors,
      success,
      warnings
    };
  }

  /**
   * Creates a new order for a given array of transaction ids using the specified courier service.
   * @param {number[]} [ids=[]] - The array of transaction ids.
   * @param {number} [itemsPerBatch=20] - The number of items to process per batch.
   * @param {Function} [progressCallBack=params => {}] - A callback function to update the progress bar.
   * @param {number} [sleepMS=3000] - The time in milliseconds to sleep between batches.
   * @returns {Promise<Object>} - An object containing the results of the processing.
   * The object returned contains the following properties:
   * - `errors`: An array of objects containing the id and error message for each failed item.
   * - `success`: An array of objects containing the id and success message for each successful item.
   * - `warnings`: An array of objects containing the id and warning message for each item that generated a warning.
   */
  async function batchCreateOrder(
    ids = [],
    itemsPerBatch = 20,
    progressCallBack = params => {},
    sleepMS = 3000
  ) {
    // create batch
    const batches = _.chunk(ids, itemsPerBatch);

    const errors = [],
      success = [],
      warnings = [];

    let currentBatch = 1,
      itemsDone = 0;

    const setup = getCourierSetup();

    if (
      isEmpty(setup.trans) ||
      isEmpty(setup.sub_map) ||
      isEmpty(setup.providers)
    ) {
      return {
        warnings,
        success,
        errors: ids.map(item => ({
          id: item,
          msg: 'Courier settings are not configured correctly'
        }))
      };
    }

    for await (const batch of batches) {
      // init progress
      if (currentBatch === 1) {
        progressCallBack({
          batches: batches.length,
          currentBatch,
          progress: 0,
          batchItems: batch.length,
          totalItemsDone: 0,
          totalItems: ids.length,
          errors: 0,
          success: 0,
          warnings: 0
        });
      }

      const items = await getItemsDetails(batch);

      let index = 0;
      for await (const item of items) {
        if (!item.success) {
          errors.push({
            id: batch[index],
            msg: item.errorMessage
          });
          index += 1;
          continue;
        }

        const validateResult = validateSetup(item?.data?.jv ?? {});
        if (!validateResult.success) {
          errors.push({
            id: batch[index],
            msg: validateResult.msg
          });
          index += 1;
          continue;
        }

        const { jv, courier, transConfig, mailNoFld } = getTransactionDetails(
          item?.data?.jv ?? {}
        );

        const orderDetails = await getOrderDetails(courier, jv, transConfig);
        const orderResult = await createOrder(courier, orderDetails);

        if (!orderResult.success) {
          errors.push({
            id: batch[index],
            msg: orderResult.explanation
          });
          index += 1;
          continue;
        }

        const updateResult = await req.put(`/trans/jcd/${jv?.jcd_uuid}/save`, {
          jid: jv?.jid,
          ixTL: jv?.ixTL,
          kvs: {
            [mailNoFld]: orderResult.mailno
          }
        });

        if (!updateResult.success) {
          errors.push({
            id: batch[index],
            msg: `Update failed: Use waybill ${orderResult.mailno} for manual update`
          });
          continue;
        }

        success.push({
          id: batch[index],
          msg: `Waybill Successfully Created - ${orderResult.mailno}`
        });

        index += 1;
      }

      // update progress
      if (batches.length !== currentBatch) currentBatch += 1;
      itemsDone += batch.length;

      progressCallBack({
        batches: batches.length,
        currentBatch,
        progress: itemsDone / ids.length,
        batchItems: batches[currentBatch - 1].length,
        totalItemsDone: itemsDone,
        totalItems: ids.length,
        errors: errors.length,
        success: success.length,
        warnings: warnings.length
      });

      // sleep
      if (batches.length >= currentBatch) await wait(sleepMS);
    }

    return {
      errors,
      success,
      warnings
    };
  }

  return {
    createOrder,
    trackOrder,
    getOrderDetails,
    getCourierSetup,
    validateSetup,
    batchStatusUpdate,
    batchCreateOrder
  };
}

export default useCourierService;
