import React from 'react';
import { Switch, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { COLOR_VALUES, HOLIDAY_TYPES, SIL_TYPES } from 'src/constants';
import { formatTimeToInt, formatTimeToStr, formatDateToStr } from 'src/utils';
import numeral from 'numeral';
import { MenuItem, TextField } from '@material-ui/core';
import { isNumber, isString } from 'lodash';
import { OpenInNew } from '@material-ui/icons';
import clsx from 'clsx';

function formatDateToStrWithDay(date) {
  if (isString(date)) return date;

  const formattedDate = formatDateToStr(date);
  return moment(formattedDate).format('YYYY-MM-DD (ddd)');
}

const useStyles = makeStyles({
  noPadding: {
    padding: '0 !important'
  },
  blueFont: {
    color: COLOR_VALUES.BLUE + ' !important'
  },
  regularHoliday: {
    backgroundColor: COLOR_VALUES.REGULAR_HOLIDAY + ' !important'
  },
  specialHoliday: {
    backgroundColor: COLOR_VALUES.SPECIAL_HOLIDAY + ' !important'
  },
  error: {
    backgroundColor: COLOR_VALUES.ERROR + ' !important'
  },
  hr: {
    backgroundColor: COLOR_VALUES.HR + ' !important'
  },
  sys: {
    backgroundColor: COLOR_VALUES.SYS + ' !important'
  },
  ob: {
    backgroundColor: COLOR_VALUES.OB + ' !important'
  },
  restDayBg: {
    backgroundColor: COLOR_VALUES.REST_DAY + ' !important'
  },
  leaveClass: {
    backgroundColor: COLOR_VALUES.LEAVE + ' !important'
  },
  exempt: {
    backgroundColor: COLOR_VALUES.EXEMPT + ' !important'
  },
  thickRightBorder: {
    borderRightWidth: '3px !important'
  },
  textField: {
    '& .MuiInputBase-input': {
      fontSize: '0.875rem'
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: 12
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  },
  menuItem: {
    fontSize: '0.875rem'
  }
});

const OB_OT = [SIL_TYPES.OB, SIL_TYPES.OT, SIL_TYPES.SELFIE_LOGIN];

function getSchedOfField({
  workHours: { am_in, am_out, pm_in, pm_out },
  field
}) {
  if (field === 'HR_AM_IN') return am_in;
  if (field === 'HR_AM_OUT') return am_out;
  if (field === 'HR_PM_IN') return pm_in;
  if (field === 'HR_PM_OUT') return pm_out;
  return '';
}

const mergeClass = (classes = []) => {
  return classes.join(' ');
};

/**
 *
 * @param {Object} args
 * @param {policy} args.policy
 * @param {0|1|2} args.prMode
 * @param {Array<{label: string, var: string, suffix: string, width: number}>} args.varToShow
 * @param {{allow: boolean, scheds: Array<{id: any, caption: string, updates: object}>}} args.fixedSched
 * @param {boolean} args.bioExempt
 * @param {VoidFunction} args.showPrevOt
 * @returns
 */
const useTimeLogColumns = ({
  holidays = [],
  leaves = [],
  maxLogColumn = 4,
  editable = false,
  onCellEditCommit = () => {},
  policy = {},
  shownColumns = [],
  varToShow = [],
  prMode = 0,
  branch = 0,
  fixedSched = {},
  updateSched = () => {},
  bioExempt = false,
  showPrevOt = () => {},
  freezeCol = 0
}) => {
  const {
    blueFont,
    regularHoliday,
    specialHoliday,
    error,
    ob,
    restDayBg,
    leaveClass,
    thickRightBorder,
    noPadding,
    textField,
    menuItem,
    exempt
  } = useStyles();

  const showOT = shownColumns.includes('overtime');
  const showShifts = shownColumns.includes('shifts');
  const showND = shownColumns.includes('nightDifferential');
  const showRD = shownColumns.includes('restDay');
  const showVariables = shownColumns.includes('variables');
  const showAccRemarks = shownColumns.includes('acc_remarks');
  const showOtValue = shownColumns.includes('ot_value');

  const LeaveDesc = {
    [SIL_TYPES.SICK_LEAVE]: 'Sick',
    [SIL_TYPES.VACATION_LEAVE]: 'Vacation',
    [SIL_TYPES.MATERNITY_LEAVE]: 'Maternity',
    [SIL_TYPES.PATERNITY_LEAVE]: 'Paternity',
    [SIL_TYPES.BEREAVEMENT_LEAVE]: 'Bereavement',
    [SIL_TYPES.UNPAID_LEAVE]: 'Unpaid',
    [SIL_TYPES.OB]: 'Official Bus.',
    [SIL_TYPES.SIL]: 'SIL',
    [SIL_TYPES.PRIVILEGE_LEAVE]: 'PL'
  };

  function checkHoliday(date) {
    return (
      holidays.find(
        item =>
          item.ixDate === date &&
          Boolean(item.ixDIV) &&
          (!item.ixBrch || item.ixBrch === branch)
      ) || holidays.find(item => item.ixDate === date)
    );
  }

  function renderCellWithLeave({ row, value, field, id }) {
    if (!isNumber(id)) return '';

    const leave = leaves.find(
      item =>
        item.ixDate === row.ixDate &&
        item.ixEmp === row.ixEmp &&
        !OB_OT.includes(item.ixSILType)
    );

    const schedTime = getSchedOfField({
      workHours: {
        am_in: row.SHF1_HR_IN,
        am_out: row.SHF1_HR_OUT,
        pm_in: row.SHF2_HR_IN,
        pm_out: row.SHF2_HR_OUT
      },
      field
    });
    const intSchedTime = formatTimeToInt(schedTime);

    const obExists = leaves.some(
      item =>
        item.ixDate === row.ixDate &&
        item.ixEmp === row.ixEmp &&
        item.ixSILType === SIL_TYPES.OB &&
        item.ixTime1 <= intSchedTime &&
        item.ixTime2 >= intSchedTime
    );

    if ((!leave && !obExists && !row.RestDay) || value)
      return value
        ? moment(value, 'HH:mm').format('hh:mm a')
        : bioExempt
        ? 'Exempt'
        : '';

    if (obExists) return LeaveDesc[SIL_TYPES.OB];

    if (row.RestDay) return 'Rest Day';

    const qty = leave.qtyOUT / 100;

    // if field is am and am logs is empty
    const amLeave =
      (field === 'HR_AM_IN' || field === 'HR_AM_OUT') &&
      (!row.HR_AM_IN || !row.HR_AM_OUT);

    // if field is pm and am is not empty and pm is empty
    const pmLeave =
      (field === 'HR_PM_IN' || field === 'HR_PM_OUT') &&
      row.HR_AM_IN &&
      row.HR_AM_OUT &&
      (!row.HR_PM_IN || !row.HR_PM_OUT);

    if (
      // if whole day
      qty === 1 ||
      amLeave ||
      pmLeave
    )
      return LeaveDesc[leave.ixSILType];

    return '';
  }

  function getClass({
    rowProps: { date, row, field },
    highlightNonZeroValues = false,
    highlightEmptyValues = false,
    highlightLeave = false
  }) {
    let color = '',
      bg = '',
      hasLeave = false;

    if (highlightNonZeroValues && row[field]) color = blueFont;

    const holiday = checkHoliday(date);

    if (highlightLeave) {
      const { HR_AM_IN, HR_AM_OUT, HR_PM_IN, HR_PM_OUT, ixEmp } = row;
      const leave = leaves.find(
        item =>
          item.ixDate === date &&
          item.ixEmp === ixEmp &&
          !OB_OT.includes(item.ixSILType)
      );

      const schedTime = getSchedOfField({
        workHours: {
          am_in: row.SHF1_HR_IN,
          am_out: row.SHF1_HR_OUT,
          pm_in: row.SHF2_HR_IN,
          pm_out: row.SHF2_HR_OUT
        },
        field
      });
      const intSchedTime = formatTimeToInt(schedTime);

      const obExists = leaves.some(
        item =>
          item.ixDate === row.ixDate &&
          item.ixEmp === row.ixEmp &&
          item.ixSILType === SIL_TYPES.OB &&
          item.ixTime1 <= intSchedTime &&
          item.ixTime2 >= intSchedTime
      );

      if (obExists) {
        hasLeave = true;
        bg = ob;
      }

      if (leave) {
        hasLeave = true;
        const qty = leave.qtyOUT / 100;

        // FIELD IS AM AND AM LOGS IS EMPTY OR PM LOGS HAS VALUE
        const isMorningLeave =
          (field === 'HR_AM_IN' || field === 'HR_AM_OUT') &&
          (!HR_AM_IN || !HR_AM_OUT || (HR_PM_IN && HR_PM_OUT));

        // FIELD IS PM AND AM LOGS IS NOT EMPTY AND PM LOGS IS EMPTY
        const isAfternoonLeave =
          (field === 'HR_PM_IN' || field === 'HR_PM_OUT') &&
          HR_AM_IN &&
          HR_AM_OUT &&
          (!HR_PM_IN || !HR_PM_OUT);

        if (qty === 1 || isMorningLeave || isAfternoonLeave) bg = leaveClass;
      }
    }

    if (
      holiday &&
      holiday.ixHolidayType === HOLIDAY_TYPES.REGULAR_HOLIDAY &&
      !hasLeave
    )
      bg = regularHoliday;
    else if (
      holiday &&
      holiday.ixHolidayType === HOLIDAY_TYPES.SPECIAL_HOLIDAY &&
      !hasLeave
    )
      bg = specialHoliday;
    else if (row.RestDay) bg = restDayBg;
    else if (date && highlightLeave && bioExempt) bg = exempt;
    else if (
      highlightEmptyValues &&
      date &&
      !row[field] &&
      !hasLeave &&
      !row.restDay
    )
      bg = error;

    return (bg + ' ' + color).trim();
  }

  function generateLogsColumns(start = 1, end = 12) {
    /**
     * @type {column[]}
     */
    const res = [];

    for (start; start <= end; start++) {
      const isLast = start === end;

      res.push({
        field: 'BIO' + start,
        headerName: 'Log ' + start,
        flex: 1,
        minWidth: 100,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: isLast ? thickRightBorder : '',
        cellClassName: ({ id, row }) =>
          mergeClass([
            getClass({ rowProps: { date: id, row } }),
            isLast ? +thickRightBorder : ''
          ]),
        valueFormatter: ({ value }) =>
          value ? formatTimeToStr(value, 'hh:mm a') : ''
      });
    }

    return res;
  }

  const SHIFT_FIELDS = [
    {
      field: 'SHF1_HR_IN',
      headerName: 'Shift In',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field }
        }),
      valueFormatter: ({ value }) =>
        value ? moment(value, 'HH:mm').format('hh:mm a') : ''
    },
    ...(policy.noNoonLogs
      ? []
      : [
          {
            field: 'SHF1_HR_OUT',
            headerName: 'Shift Out',
            minWidth: 100,
            flex: 1,
            sortable: false,
            editable,
            headerAlign: 'center',
            align: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field }
              }),
            valueFormatter: ({ value }) =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : ''
          },
          {
            field: 'SHF2_HR_IN',
            headerName: 'Shift In',
            minWidth: 100,
            flex: 1,
            sortable: false,
            editable,
            headerAlign: 'center',
            align: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field }
              }),
            valueFormatter: ({ value }) =>
              value ? moment(value, 'HH:mm').format('hh:mm a') : ''
          }
        ]),
    {
      field: 'SHF2_HR_OUT',
      headerName: 'Shift Out',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      headerClassName: thickRightBorder,
      cellClassName: ({ id, row, field }) =>
        mergeClass([
          getClass({
            rowProps: { date: id, row, field }
          }),
          thickRightBorder
        ]),
      valueFormatter: ({ value }) =>
        value ? moment(value, 'HH:mm').format('hh:mm a') : ''
    }
  ];

  function getSchedValue(row) {
    const sched = (fixedSched?.scheds || []).find(item => {
      let matched = true;

      for (const key of Object.keys(item?.updates || {})) {
        if (item.updates[key] !== formatTimeToInt(row[key])) {
          matched = false;
          break;
        }
      }

      return matched;
    });

    return sched ? sched.id : 'na';
  }

  function getShiftFields() {
    if (fixedSched?.allow) {
      return [
        {
          field: 'shift',
          headerName: 'Shift',
          width: 140,
          sortable: false,
          align: 'left',
          editable,
          headerAlign: 'center',
          headerClassName: thickRightBorder,
          cellClassName: ({ id, row, field }) =>
            mergeClass([
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
              thickRightBorder,
              noPadding
            ]),
          renderCell: ({ id, row }) =>
            id ? (
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
                inputProps={{ tabIndex: -1 }}
                value={getSchedValue(row)}
                onChange={e => updateSched(id, e.target.value)}
                className={clsx(textField)}
                style={{ height: 36 }}
              >
                <MenuItem className={menuItem} value="na" disabled>
                  Custom
                </MenuItem>
                {(fixedSched?.scheds || []).map(sched => (
                  <MenuItem
                    className={menuItem}
                    key={sched.id}
                    value={sched.id}
                  >
                    {sched.caption}
                  </MenuItem>
                ))}
              </TextField>
            ) : null
        },
        ...SHIFT_FIELDS
      ];
    }

    return SHIFT_FIELDS;
  }

  const ND_FIELDS = [
    {
      field: 'vND',
      headerName: 'ND',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_ND',
      headerName: 'ND OT',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vND_RD',
      headerName: 'ND RD',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vND_RH',
      headerName: 'ND RH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vND_SH',
      headerName: 'ND SH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vND_RD_RH',
      headerName: 'ND RD RH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vND_RD_SH',
      headerName: 'ND RD SH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      headerClassName: showVariables ? thickRightBorder : '',
      cellClassName: ({ id, row, field }) =>
        mergeClass([
          getClass({
            rowProps: { date: id, row, field },
            highlightNonZeroValues: true
          }),
          showVariables ? thickRightBorder : ''
        ]),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    }
  ];

  const VAR_FIELDS = varToShow.map(colVar => ({
    field: colVar.var,
    headerName: colVar?.label || colVar.var,
    width: colVar?.width || 100,
    sortable: false,
    align: 'right',
    editable,
    headerAlign: 'center',
    cellClassName: ({ id, row, field }) =>
      getClass({
        rowProps: { date: id, row, field },
        highlightNonZeroValues: true
      }),
    valueFormatter: ({ value }) =>
      value ? `${numeral(value).format('0,0.00')}${colVar?.suffix || ''}` : '-'
  }));

  const OT_VALUE = showOtValue
    ? [
        {
          field: 'valueOT',
          headerName: 'OT Value',
          minWidth: 120,
          flex: 1,
          sortable: false,
          editable,
          headerAlign: 'center',
          align: 'right',
          cellClassName: ({ id, row, field }) =>
            getClass({
              rowProps: { date: id, row, field },
              highlightNonZeroValues: true
            }),
          valueFormatter: ({ value }) =>
            value ? numeral(value).format('0,0.00') : '-'
        }
      ]
    : [];

  const OT_FIELDS = [
    {
      field: 'HR_OT_IN',
      headerName: 'OT In',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field }
        }),
      valueFormatter: ({ value }) =>
        value ? moment(value, 'HH:mm').format('hh:mm a') : ''
    },
    {
      field: 'HR_OT_OUT',
      headerName: 'OT Out',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field }
        }),
      valueFormatter: ({ value }) =>
        value ? moment(value, 'HH:mm').format('hh:mm a') : ''
    },
    {
      field: 'vOT_BRK',
      headerName: 'OT Break',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'right',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    ...OT_VALUE
  ];

  const OT_VAR_FIELDS = [
    {
      field: 'vOT',
      headerName: 'OT',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_RD',
      headerName: 'RD Work',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_RH',
      headerName: 'OT RH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_SH',
      headerName: 'OT SH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_RD_OT',
      headerName: 'RD OT',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_RD_RH',
      headerName: 'OT RD RH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    },
    {
      field: 'vOT_RD_SH',
      headerName: 'OT RD SH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      headerClassName: showND ? thickRightBorder : '',
      cellClassName: ({ id, row, field }) =>
        mergeClass([
          getClass({
            rowProps: { date: id, row, field },
            highlightNonZeroValues: true
          }),
          showND ? thickRightBorder : ''
        ]),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' h' : '-'
    }
  ];

  const NOON_LOGS = [
    {
      field: 'HR_AM_OUT',
      headerName: 'Out',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightLeave: true,
          highlightEmptyValues: true
        }),
      valueFormatter: renderCellWithLeave
    },
    {
      field: 'HR_PM_IN',
      headerName: 'In',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightLeave: true,
          highlightEmptyValues: true
        }),
      valueFormatter: renderCellWithLeave
    }
  ];

  /**
   * @type {column[]}
   */
  const columns = [
    {
      field: 'ixDate',
      headerName: 'Date',
      minWidth: 170,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      cellClassName: ({ id, row }) => getClass({ rowProps: { date: id, row } }),
      renderCell: ({ row, value }) => {
        if (row?.renderPrevPr)
          return (
            <span>
              {value}
              <Tooltip title="View details from previous payroll">
                <IconButton size="small" color="primary" onClick={showPrevOt}>
                  <OpenInNew fontSize="small" />
                </IconButton>
              </Tooltip>
            </span>
          );

        return value ? formatDateToStrWithDay(value) : '';
      }
    },
    ...generateLogsColumns(1, maxLogColumn),
    ...(showShifts ? getShiftFields() : []),
    {
      field: 'HR_AM_IN',
      headerName: 'In',
      minWidth: 100,
      flex: 1,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightLeave: true,
          highlightEmptyValues: true
        }),
      valueFormatter: renderCellWithLeave
    },
    ...(policy.noNoonLogs ? [] : NOON_LOGS),
    {
      field: 'HR_PM_OUT',
      headerName: 'Out',
      flex: 1,
      minWidth: 100,
      sortable: false,
      editable,
      headerAlign: 'center',
      align: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightLeave: true,
          highlightEmptyValues: true
        }),
      valueFormatter: renderCellWithLeave
    },
    ...(showOT ? OT_FIELDS : []),
    {
      field: 'HR_remarks',
      headerName: 'HR Remarks',
      flex: 1,
      minWidth: 200,
      sortable: false,
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        mergeClass([
          getClass({
            rowProps: { date: id, row, field }
          }),
          showAccRemarks ? '' : thickRightBorder
        ]),
      headerClassName: showAccRemarks ? '' : thickRightBorder
    },
    ...(showAccRemarks
      ? [
          {
            field: 'ACC_remarks',
            headerName: 'Acc Remarks',
            flex: 1,
            minWidth: 200,
            sortable: false,
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              mergeClass([
                getClass({
                  rowProps: { date: id, row, field }
                }),
                thickRightBorder
              ]),
            headerClassName: thickRightBorder
          }
        ]
      : []),
    ...(showRD
      ? [
          {
            field: 'RestDay',
            headerName: 'Rest Day',
            width: 100,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            renderCell: ({ value, id, field }) =>
              id ? (
                <Switch
                  size="small"
                  checked={Boolean(value)}
                  tabIndex={-1}
                  onChange={e =>
                    onCellEditCommit({ id, field, value: e.target.checked })
                  }
                />
              ) : null
          }
        ]
      : []),
    ...(prMode === 1
      ? [
          {
            field: 'vPresent',
            headerName: 'Present',
            width: 100,
            sortable: false,
            align: 'right',
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            valueFormatter: ({ value }) =>
              value ? numeral(value).format('0,0.00') + ' d' : '-'
          }
        ]
      : []),
    ...(prMode !== 2
      ? [
          {
            field: 'vLate',
            headerName: 'Late',
            width: 100,
            sortable: false,
            align: 'right',
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            valueFormatter: ({ value }) =>
              value ? numeral(value).format('0,0') + ' m' : '-'
          },
          {
            field: 'vUT',
            headerName: 'UT',
            width: 100,
            sortable: false,
            align: 'right',
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            valueFormatter: ({ value }) =>
              value ? numeral(value).format('0,0') + ' m' : '-'
          }
        ]
      : []),
    ...(prMode === 0
      ? [
          {
            field: 'vAbsent',
            headerName: 'Absent',
            width: 100,
            sortable: false,
            align: 'right',
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            valueFormatter: ({ value }) =>
              value ? numeral(value).format('0,0.00') + ' d' : '-'
          }
        ]
      : []),

    ...(prMode === 2
      ? [
          {
            field: 'vHrs',
            headerName: 'Hrs Worked',
            width: 120,
            sortable: false,
            align: 'right',
            editable,
            headerAlign: 'center',
            cellClassName: ({ id, row, field }) =>
              getClass({
                rowProps: { date: id, row, field },
                highlightNonZeroValues: true
              }),
            valueFormatter: ({ value }) =>
              value ? numeral(value).format('0,0.00') + ' h' : '-'
          }
        ]
      : []),
    {
      field: 'vRH',
      headerName: 'RH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      cellClassName: ({ id, row, field }) =>
        getClass({
          rowProps: { date: id, row, field },
          highlightNonZeroValues: true
        }),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' d' : '-'
    },
    {
      field: 'vSH',
      headerName: 'SH',
      width: 100,
      sortable: false,
      align: 'right',
      editable,
      headerAlign: 'center',
      headerClassName: thickRightBorder,
      cellClassName: ({ id, row, field }) =>
        mergeClass([
          getClass({
            rowProps: { date: id, row, field },
            highlightNonZeroValues: true
          }),
          thickRightBorder
        ]),
      valueFormatter: ({ value }) =>
        value ? numeral(value).format('0,0.00') + ' d' : '-'
    },
    ...(showOT ? OT_VAR_FIELDS : []),
    ...(showND ? ND_FIELDS : []),
    ...(showVariables ? VAR_FIELDS : [])
  ];

  columns[freezeCol].sticky = true;

  return columns;
};

export default useTimeLogColumns;
