import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  InputAdornment,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
  Collapse,
  Tooltip,
  Zoom,
  CircularProgress,
  useTheme,
  FormControlLabel,
  Switch,
  Checkbox
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import SrchSubResults from './SrchSubResults';
import Alert from '@material-ui/lab/Alert';
import useSubSearch from './useSubSearch';
import clsx from 'clsx';

import {
  Close,
  Search,
  Cancel,
  Clear,
  InfoOutlined,
  Add,
  Save
} from '@material-ui/icons';
import SubAccForm, { useSubAccForm } from '../SubAccForm';
import { useToggle } from 'src/hooks';
import DebounceTextField from '../DebounceTextField';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    padding: '1px',
    paddingLeft: '-2px',
    flex: 1
  },
  iconButton: {
    padding: 10,
    margin: '1px'
  },
  adornStart: {
    paddingLeft: 1
  },
  adornEnd: {
    paddingRight: 4
  },
  divider: {
    height: 28,
    margin: 2,
    marginRight: 0
  },
  dialogContent: {
    height: 500,
    padding: 0
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const SrchSub = ({
  className,
  ixSubType,
  sSubType = '',
  selectedSub,
  handleSubSelectRoot,
  onClose,
  fld,
  error,
  errorMessage,
  handleCreateSubAccount,
  showAdd = true,
  showEdit = true,
  forceOpen = false,
  isEditable = true,
  required = false,
  disregardLimits = false,
  autoSelect = true,
  hideCaption = false,
  containerProps = {},
  autoOpen = false,
  showDeactivateToggle = false,
  showInactiveToggle = false,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isOpen, open, close] = useToggle(forceOpen || autoOpen || false);

  const autoSelectIfEmpty =
    autoSelect && (selectedSub === '' || selectedSub === null);
  const additionalFlds = {
    fld,
    index: rest?.index,
    forceOpen
  };

  const {
    loading,
    subList,
    error: errorSrch,
    meta,
    closeForm,
    subAccDetails,
    query,
    setQuery,
    editSubAcc,
    isFormOpen,
    createSubAcc,
    showInactive,
    toggleInactive
  } = useSubSearch({
    ixSubType,
    sSubType,
    disregardLimits,
    autoSelect: autoSelectIfEmpty,
    selectSub: handleSubSelectRoot,
    isOpen,
    showEdit,
    showAdd,
    ...additionalFlds
  });

  const {
    isLoading,
    updateField,
    updateKVSField,
    updateSubLink,
    updateSubParent,
    addAtc,
    removeAtc,
    save,
    errors,
    sub,
    hasChanges
  } = useSubAccForm({
    subType: { ixSubType, sSubType },
    subTypeMeta: meta,
    subAcc: subAccDetails
  });

  async function handleSave() {
    const { success, data } = await save();

    if (success) {
      closeForm();
      setQuery(data?.sSub || '');
    }
  }

  const handleSrchSub = e => {
    const value = e.target.value;
    if (value !== '' || typeof value !== 'undefined' || value !== null) {
      const lastChar = value[value.length - 1];
      setQuery(lastChar);
    }

    open();
  };

  const handleSubSelect = async (item, event = null, type = null) => {
    if (type === 'edit' && event) {
      event.stopPropagation();
      editSubAcc(item.ixSub);
    } else {
      close();
      handleSubSelectRoot({
        ...item,
        ...additionalFlds
      });
    }
  };

  const cancelSubAccForm = () => {
    closeForm();
  };

  const disabled = () => {
    return ixSubType === 0 || !isEditable || loading;
  };

  return (
    <Box {...containerProps}>
      <Dialog
        onClose={close}
        fullWidth
        scroll="paper"
        TransitionComponent={Transition}
        open={isOpen}
      >
        <DialogTitle>
          {!isFormOpen ? (
            <>
              <DebounceTextField
                fullWidth
                delay={200}
                value={query}
                onChange={e => setQuery(e.target.value)}
                autoComplete="off"
                autoFocus
                InputProps={{
                  disableUnderline: true,
                  autoFocus: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {showInactiveToggle && (
                        <Tooltip title="Show inactive" arrow>
                          <Checkbox
                            checked={showInactive}
                            onChange={toggleInactive}
                            size="small"
                          />
                        </Tooltip>
                      )}
                      {showAdd && (
                        <InputAdornment position="end">
                          <Tooltip title={'Add ' + sSubType} arrow>
                            <span>
                              <IconButton
                                onClick={createSubAcc}
                                tabIndex={-1}
                                size="small"
                              >
                                <Add />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </InputAdornment>
                      )}
                      <InputAdornment position="end">
                        <Tooltip title="Close" arrow>
                          <span>
                            <IconButton
                              onClick={close}
                              tabIndex={-1}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    </>
                  )
                }}
                placeholder={sSubType || 'Search...'}
                variant="standard"
              />
              <Collapse in={forceOpen}>
                <Box mt={1}>
                  <Alert severity="info">Select {sSubType} First</Alert>
                </Box>
              </Collapse>
              <Collapse in={errorSrch}>
                <Box mt={1}>
                  <Alert severity="error">Something went wrong.</Alert>
                </Box>
              </Collapse>
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="h4"
                  component="p"
                  color="textSecondary"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <InfoOutlined style={{ marginRight: '.4em' }} />
                  {subAccDetails?.ixSub
                    ? `${sSubType} (Update)`
                    : `${sSubType} (Create)`}
                </Typography>
                <Box display="flex" gridColumnGap={theme.spacing(1)}>
                  <Tooltip title="Save" arrow>
                    <span>
                      <IconButton
                        size="small"
                        tabIndex={-1}
                        onClick={handleSave}
                      >
                        <Save />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Cancel" arrow>
                    <span>
                      <IconButton
                        size="small"
                        tabIndex={-1}
                        onClick={cancelSubAccForm}
                      >
                        <Cancel />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress size={40} />
            </Box>
          )}
          {!loading && !isFormOpen && subList.length === 0 && query === '' && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Search color="primary" fontSize="large" />
                <Typography variant="h5" color="primary">
                  Search {sSubType}
                </Typography>
              </Box>
            </Box>
          )}
          {!loading &&
            !isFormOpen &&
            subList.length === 0 &&
            (query || '').trim() !== '' && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Search color="primary" fontSize="large" />
                  <Typography variant="h5" color="primary">
                    No Result Found
                  </Typography>
                </Box>
              </Box>
            )}
          {!loading && subList.length > 0 && !isFormOpen && (
            <SrchSubResults
              subList={subList}
              handleSubSelect={handleSubSelect}
              showEdit={showEdit}
            />
          )}
          {/* Create and Update */}
          {!loading && isFormOpen && (
            <Box p={2}>
              {/* <SubAccForm
                subTypeMeta={meta}
                subAcc={sub}
                updateField={updateField}
                updateKVSField={updateKVSField}
                updateSubParent={updateSubParent}
                updateSubLink={updateSubLink}
                addAtc={addAtc}
                removeAtc={removeAtc}
                error={errors}
                loading={isLoading}
                showContainer={false}
                hasChanges={hasChanges}
                showDeactivateToggle={showDeactivateToggle}
              /> */}
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Box className={clsx(classes.root, className)}>
        <TextField
          className={classes.input}
          disabled={disabled()}
          fullWidth
          label={hideCaption ? '' : sSubType}
          name="SrchSub"
          value={selectedSub}
          error={error}
          helperText={errorMessage}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="search"
                  onClick={() => {
                    setQuery('');
                    open();
                  }}
                  disabled={disabled()}
                  size={rest?.size ?? 'medium'}
                >
                  <Search fontSize={rest?.size || 'medium'} />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={e => {
                    e.stopPropagation();
                    handleSubSelect({
                      ixSubAcc: '',
                      sSubAcc: '',
                      ixSub: '',
                      sSub: '',
                      sub_id: '',
                      sub_title: ''
                    });
                  }}
                  size={rest?.size || 'medium'}
                  disabled={disabled()}
                >
                  <Clear
                    fontSize={rest?.size || 'medium'}
                    style={{
                      color: disabled() ? 'rgba(0, 0, 0, 0.38)' : red[500]
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),

            classes: {
              adornedStart: classes.adornStart,
              adornedEnd: classes.adornEnd
            }
          }}
          placeholder={rest?.placeholder ?? 'Please select a sub-account...'}
          variant="outlined"
          onChange={handleSrchSub}
          size={rest?.size ?? 'medium'}
          required={required}
        />
      </Box>
    </Box>
  );
};

export default SrchSub;
