import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {};

const slice = createSlice({
  name: 'daloy',
  initialState: INITIAL_STATE,
  reducers: {
    setStatus(state, action) {
      state[action.payload.key] = action.payload.value;
    },
    clear() {
      return INITIAL_STATE;
    },
    restore(_, action) {
      return action.payload || INITIAL_STATE;
    }
  }
});

const wfReducer = slice.reducer;

export const { setStatus, restore, clear } = slice.actions;
export default wfReducer;
