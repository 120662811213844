import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, isString } from 'lodash';
import useNotif from 'src/hooks/useNotif';
import useBizState from 'src/hooks/useBizState';
import { MAX_BIZ_PER_PAGE, MAX_RECENT_TO_DISPLAY } from 'src/constants';
import { fetchBizSettings, loadBizSettings } from 'src/redux/slices/biz';
import { selectBIZ, setRecentlyOpenedBiz } from 'src/redux/slices/auth';
import { setBizList } from 'src/redux/slices/registry';
import { useToggle, useRequest } from 'src/hooks';

function useSelectBiz({ loadBizWhenOpen = false, open = false }) {
  const notify = useNotif();
  const dispatch = useDispatch();

  const [loading, loadingOn, loadingOff] = useToggle();
  const [disableSelect, setDisableSelect] = useState(false);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState({
    status: false,
    msg: ''
  });

  const {
    restore: restoreBizState,
    clear: clearCurrentBizState
  } = useBizState();

  const {
    base_url,
    current_user,
    recentlyOpenedBiz = [],
    ixBiz: currentBiz,
    loginToken
  } = useSelector(({ auth }) => auth);

  const { bizList = [] } = useSelector(state => state.registry);

  const request = useRequest(loadingOn, loadingOff);

  const filteredBizList = useMemo(() => {
    if (filter.trim() === '') return bizList;
    const properties = ['sBiz', 'Address1', 'Address2', 'cdBiz'];

    return bizList.filter(data => {
      const shortcut = data.sBiz
        .split(/\s+/)
        .reduce((str, current) => str + (current?.[0] || ''), '');
      const lc_shortcut = isString(shortcut) ? shortcut.toLowerCase() : '';

      const filter_lowerCase = filter.toLowerCase();

      return (
        lc_shortcut.includes(filter_lowerCase) ||
        properties.some(property =>
          data[property].toLowerCase().includes(filter_lowerCase)
        )
      );
    });
  }, [bizList, filter]);

  const filteredRecentlyOpened = useMemo(() => {
    const res = [];

    for (const recentBiz of recentlyOpenedBiz) {
      const bizDetails = filteredBizList.find(
        biz => biz.biz_uuid === recentBiz.biz_uuid
      );

      if (bizDetails) res.push(bizDetails);
      if (res.length === MAX_RECENT_TO_DISPLAY) break;
    }

    return res;
  }, [filteredBizList, recentlyOpenedBiz]);

  const finalBizList = useMemo(() => {
    if (filter.trim() !== '') return filteredBizList;

    return filteredBizList.filter(
      biz1 =>
        !filteredRecentlyOpened.some(biz2 => biz2.biz_uuid === biz1.biz_uuid)
    );
  }, [filteredBizList, filteredRecentlyOpened, filter]);

  const pageBizList = useMemo(() => {
    return finalBizList.slice(
      currentPage * MAX_BIZ_PER_PAGE - MAX_BIZ_PER_PAGE,
      currentPage * MAX_BIZ_PER_PAGE
    );
  }, [finalBizList, currentPage]);

  const bizMenuItems = useMemo(() => {
    return filteredBizList.slice(0, currentPage * MAX_BIZ_PER_PAGE);
  }, [filteredBizList, currentPage]);

  function bizChangeStatus({ ixBiz, status }) {
    dispatch(
      setBizList(
        bizList.map(data => {
          if (data.ixBiz !== ixBiz) return data;
          return { ...data, selected: status };
        })
      )
    );
  }

  function bizInfo({ ixBiz }) {
    const bizListCopy = [...bizList];
    const indx = bizListCopy.findIndex(data => data.ixBiz === ixBiz);
    return bizListCopy[indx];
  }

  function addBizToRecentlyOpened(biz_uuid) {
    const selectedBiz = filteredBizList.find(
      item => item.biz_uuid === biz_uuid
    );

    if (!selectedBiz) return;

    const clone = cloneDeep(selectedBiz);
    const recentBizIndex = recentlyOpenedBiz.findIndex(
      item => item.biz_uuid === biz_uuid
    );

    delete clone.selected;

    dispatch(
      setRecentlyOpenedBiz([
        clone,
        ...(recentBizIndex === -1
          ? recentlyOpenedBiz
          : recentlyOpenedBiz.filter((_, i) => i !== recentBizIndex))
      ])
    );
  }

  async function handleSelectBiz(ixBiz, biz_uuid, ixSandbox = 0) {
    setDisableSelect(true);
    bizChangeStatus({
      ixBiz,
      status: true
    });
    setError({
      status: false,
      msg: ''
    });

    // if current biz is not equal to selected biz, clear current biz data
    if (ixBiz !== currentBiz) clearCurrentBizState();

    const selectBizPayload = {};

    if (ixSandbox) selectBizPayload.ixSandbox = ixSandbox;

    const {
      success,
      data,
      error
    } = await request
      .overrideLoadingDisplay()
      .post(`/set-biz/${ixBiz}`, selectBizPayload, {
        headers: {
          'x-access-tokens': loginToken
        }
      });

    if (!success) {
      setDisableSelect(false);
      bizChangeStatus({
        ixBiz,
        status: false
      });
      setError({
        status: true,
        msg:
          error?.data?.msg ??
          error?.data?.description ??
          error?.data ??
          error.statusText ??
          'Something went wrong.'
      });
      return false;
    }

    // set biz
    const bizSettings = await fetchBizSettings(base_url, data.token);

    bizChangeStatus({
      ixBiz,
      status: false
    });
    setDisableSelect(false);
    dispatch(loadBizSettings(bizSettings));

    const biz_uuid_w_sandbox = ixSandbox
      ? biz_uuid + '-' + ixSandbox
      : biz_uuid;

    // restore biz state
    restoreBizState(biz_uuid_w_sandbox, bizSettings);

    // set biz info
    dispatch(
      selectBIZ({
        ...data,
        bizInfo: bizInfo({
          ixBiz
        })
      })
    );

    // add selected biz to recently opened
    addBizToRecentlyOpened(biz_uuid);

    // navigate to dashboard once biz is selected
    // navigate('/app/dashboard');
    // clear search filter
    setFilter('');

    return true;
  }

  async function handleSelectRecentBiz(ixBiz, biz_uuid, ixSandbox) {
    const biz = filteredBizList.find(item => item.biz_uuid === biz_uuid);

    if (!biz) {
      notify.error('Biz not found. Please try to refresh page.');
      return;
    }

    return await handleSelectBiz(biz.ixBiz, biz.biz_uuid, ixSandbox);
  }

  async function getBizList(cb = () => {}) {
    if (bizList.length > 0) return;

    const { success, data, error } = await request.get(
      current_user?.ru ? '/select-biz-all' : '/select-biz',
      {},
      cb
    );

    if (!success) {
      setError({
        status: true,
        msg:
          error?.data?.msg ??
          error?.data?.description ??
          error?.data ??
          error.statusText ??
          'Something went wrong.'
      });

      return;
    }

    dispatch(setBizList(data));
  }

  async function refreshBizList() {
    const { success, data, error } = await request.get(
      current_user?.ru ? '/select-biz-all' : '/select-biz'
    );

    if (!success) {
      setError({
        status: true,
        msg:
          error?.data?.msg ??
          error?.data?.description ??
          error?.data ??
          error.statusText ??
          'Something went wrong.'
      });

      return;
    }

    dispatch(setBizList(data));
  }

  useEffect(() => {
    if (loadBizWhenOpen) return;

    let cancel = () => {};

    getBizList(function(c) {
      cancel = c;
    });

    return () => {
      cancel();
    };
  }, []);

  useEffect(() => {
    let cancel = () => {};

    if (loadBizWhenOpen && open && bizList.length === 0)
      getBizList(function(c) {
        cancel = c;
      });

    return () => {
      cancel();
    };
  }, [open]);

  return {
    loading,
    bizList,
    error,
    filteredBizList,
    disableSelect,
    setFilter,
    filter,
    currentPage,
    current_user,
    bizMenuItems,
    filteredRecentlyOpened,
    setCurrentPage,
    handleSelectBiz,
    handleSelectRecentBiz,
    finalBizList,
    pageBizList,
    refreshBizList
  };
}

export default useSelectBiz;
